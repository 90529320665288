<template>
  <v-container>
    <!-- global conf -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mx-auto">
          <v-toolbar dark color="grey" dense flat>
            <v-toolbar-title class="white--text"
              >Global Configuration</v-toolbar-title
            >

            <v-btn
              icon
              link
              class="list-button ml-2"
              :href="helpURL('global-configuration')"
              target="_blank"
            >
              <v-icon size="18" class="mt-0" color="orange darken-4"
                >mdi-help</v-icon
              >
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="4">
                <TextEditor
                  class="ml-10 mt-1"
                  v-bind:value="String(modifiedConfig['pipeline-id'])"
                  title="Pipeline ID"
                  eventName="pipeline-id-updated"
                  v-bind:options="{
                    required: true,
                    readonly: true,
                  }"
                />
              </v-col>

              <v-col cols="6">
                <TextEditor
                  class="ml-10 mt-1"
                  v-bind:value="modifiedConfig['pipeline-name']"
                  title="Pipeline name"
                  eventName="pipeline-name-updated"
                  v-bind:options="{
                    required: true,
                  }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <ListEditor
                  class="ml-10 mt-1 mb-0"
                  v-bind:value="modifiedConfig['allowed-extensions']"
                  title="Allowed extensions"
                  eventName="allowed-extensions-updated"
                  v-bind:options="{
                    required: true,
                    lowerCase: true,
                    containsExtensions: true,
                  }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <ListEditor
                  class="ml-10 mt-1"
                  v-bind:value="modifiedConfig['ignored-extensions']"
                  title="Ignored extensions"
                  eventName="ignored-extensions-updated"
                  v-bind:options="{
                    lowerCase: true,
                    containsExtensions: true,
                  }"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="5">
                <TextEditor
                  class="ml-10 mt-1"
                  v-bind:value="modifiedConfig['skip-until-regexp']"
                  title="Skip until regular expression"
                  v-bind:required="true"
                  eventName="skip-until-regexp-updated"
                  v-bind:options="{
                    isRegExp: true,
                  }"
                />
              </v-col>

              <v-col cols="5">
                <TextEditor
                  class="ml-10 mt-1"
                  v-bind:value="modifiedConfig['skip-lines-regexp']"
                  title="Skip lines regular expression"
                  v-bind:required="true"
                  eventName="skip-lines-regexp-updated"
                  v-bind:options="{
                    isRegExp: true,
                  }"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- mailbox conf -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mx-auto">
          <v-toolbar dark color="grey" dense flat>
            <v-toolbar-title class="white--text"
              >Mailbox Configuration</v-toolbar-title
            >

            <v-btn
              icon
              link
              class="list-button ml-2"
              :href="helpURL('mailbox-configuration')"
              target="_blank"
            >
              <v-icon size="18" class="mt-0" color="orange darken-4"
                >mdi-help</v-icon
              >
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <ListEditor
              class="ml-10 mt-1"
              v-bind:value="modifiedConfig['allowed-domains']"
              title="Allowed domains"
              eventName="allowed-domains-updated"
              v-bind:options="{
                required: true,
                domains: true,
                lowerCase: true,
              }"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- spreadsheet conf -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mx-auto">
          <v-toolbar dark color="grey" dense flat>
            <v-toolbar-title class="white--text"
              >Spreadsheet Configuration</v-toolbar-title
            >

            <v-btn
              icon
              link
              class="list-button ml-2"
              :href="helpURL('spreadsheet-configuratio')"
              target="_blank"
            >
              <v-icon size="18" class="mt-0" color="orange darken-4"
                >mdi-help</v-icon
              >
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <ListEditor
                  class="ml-10 mt-1"
                  v-bind:value="modifiedConfig['xls-sheets-to-extract']"
                  title="XLS sheets to extract"
                  eventName="xls-sheets-to-extract-updated"
                  v-bind:options="{
                    required: false,
                  }"
                />
              </v-col>

              <BoolEditor
                class="ml-10 mt-6"
                v-bind:value="modifiedConfig['xls-use-libreoffice']"
                title="Use Libre Office"
                eventName="xls-use-libreoffice-updated"
              />
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- format -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mx-auto">
          <v-toolbar dark color="grey" dense flat>
            <v-toolbar-title class="white--text"
              >Format Configuration</v-toolbar-title
            >

            <v-btn
              icon
              link
              class="list-button ml-2"
              :href="helpURL('formats')"
              target="_blank"
            >
              <v-icon size="18" class="mt-0" color="orange darken-4"
                >mdi-help</v-icon
              >
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-expansion-panels accordion multiple v-model="openedPanels">
              <v-expansion-panel
                v-for="(item, i) in modifiedConfig.formats"
                :key="i"
              >
                <v-expansion-panel-header>{{
                  item.name
                }}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-container>
                    <v-row>
                      <v-col cols="6">
                        <TextEditor
                          class="ml-10 mt-1"
                          v-bind:value="item.name"
                          title="Format name"
                          eventName="format-name-updated"
                          v-bind:options="{
                            required: true,
                          }"
                        />
                      </v-col>

                      <v-col cols="6">
                        <DataTypeEditor
                          class="ml-10 mt-1"
                          v-bind:value="item['data-type']"
                          eventName="format-data-type-updated"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <ListEditor
                          class="ml-10 mt-1 mb-0"
                          v-bind:value="item.extensions"
                          title="Extensions"
                          eventName="format_extensions_updated"
                          v-bind:options="{
                            required: true,
                            lowerCase: true,
                            containsExtensions: true,
                          }"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <FilenameEditor
                          v-bind:value="item.filename"
                          title="Filename options"
                          eventName="format_filename_updated"
                          v-bind:options="{
                            required: true,
                            lowerCase: true,
                            containsExtensions: true,
                          }"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <ListEditor
                          class="ml-10 mt-1 mb-0"
                          v-bind:value="item.preprocessors"
                          title="Preprocessors"
                          eventName="format_preprocessors_updated"
                          v-bind:options="{
                            required: false,
                            lowerCase: false,
                          }"
                        />
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="8">
                        <div class="code-editor-container">
                          <prism-editor
                            v-model="item['query-string']"
                            language="sql"
                            style="border: 5px"
                          ></prism-editor>
                        </div>
                      </v-col>
                      <v-col cols="4">
                        <ColumnListEditor
                          v-bind:value="item.columns"
                          eventName="columns-updated"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- format override -->
    <v-row>
      <v-col cols="12">
        <v-card outlined class="mx-auto">
          <v-toolbar dark color="grey" dense flat>
            <v-toolbar-title class="white--text"
              >Format override</v-toolbar-title
            >
            <v-btn
              icon
              link
              class="list-button ml-2"
              :href="helpURL('format-override')"
              target="_blank"
            >
              <v-icon size="18" class="mt-0" color="orange darken-4"
                >mdi-help</v-icon
              >
            </v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import api from "@/services/api.js";
import ListEditor from "@/components/upload/config/ListEditor.vue";
import DataTypeEditor from "@/components/upload/config/DataTypeEditor.vue";
import TextEditor from "@/components/upload/config/TextEditor.vue";
import BoolEditor from "@/components/upload/config/BoolEditor.vue";
import ColumnListEditor from "@/components/upload/config/ColumnListEditor.vue";
import FilenameEditor from "@/components/upload/config/FilenameEditor.vue";
import { getUploadConfigHelpURL } from "@/lib/utils.js";

import PrismEditor from "vue-prism-editor";
import "prismjs/components/prism-sql";
// import "@/assets/sprout-prism-dark.css";
// import "@/assets/sprout-prism-light.css";

export default {
  components: {
    ListEditor,
    DataTypeEditor,
    TextEditor,
    BoolEditor,
    ColumnListEditor,
    FilenameEditor,
    PrismEditor,
  },

  data() {
    return {
      pipelineSlug: "",
      loading: false,
      originalConfig: {},
      modifiedConfig: {},
      openedPanels: [],
    };
  },

  created() {
    return null;
  },

  mounted() {
    this.pipelineSlug = this.$route.params.pipelineSlug;

    if (this.$vuetify.theme.dark) {
      import("../../assets/sprout-prism-dark.css");
    } else {
      import("../../assets/sprout-prism-light.css");
    }

    this.getConfig();
  },

  methods: {
    getConfig() {
      this.loading = true;
      const self = this;
      api.getUploadConfig(this.pipelineSlug).then((response) => {
        self.originalConfig = response.data;
        self.modifiedConfig = Object.assign({}, self.originalConfig);
        self.loading = false;
        if (this.modifiedConfig.formats) {
          this.openedPanels.push(0);
        }
      });
    },

    helpURL: function (helpSection) {
      return getUploadConfigHelpURL(helpSection);
    },
  },
};
</script>

<style>
.code-editor-container {
  flex: 1 1 auto;
  margin-top: 0;
  height: 100%;
  position: relative;
}

.v-application code {
  -webkit-box-shadow: none;
  box-shadow: none;
}

code.language-sql {
  color: black;
}
</style>
