<template>
  <div class="upload-config-control">
    <v-row>
      <v-text-field
        v-bind:label="titleText"
        spellcheck="false"
        v-model="computedValue"
        v-bind:rules="rules"
        v-on:blur="onBlur"
        v-bind:readonly="options.readonly"
      ></v-text-field>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!options.readonly"
            icon
            v-bind="attrs"
            v-on="on"
            class="list-button mt-3"
            v-on:click="clearValue"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
        <span>Clear</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="!options.readonly"
            icon
            v-bind="attrs"
            v-on="on"
            class="list-button mt-3"
            v-on:click="revertValue"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Undo all changes</span>
      </v-tooltip>

      <v-btn
        v-if="!!helpURL()"
        icon
        link
        class="list-button mt-3"
        :href="helpURL()"
        target="_blank"
      >
        <v-icon size="18" class="mt-0" color="blue darken-1">mdi-help</v-icon>
      </v-btn>
    </v-row>
    <sr-msg-box ref="msgbox"></sr-msg-box>
  </div>
</template>

<script>
import { getUploadConfigHelpURL, ruleFails } from "@/lib/utils.js";
import { hasValue, isValidRegExp, isInteger } from "@/lib/validators/common.js";

export default {
  data() {
    return {
      modifiedValue: "",
      originalValue: "",
      rules: [],
    };
  },

  props: {
    value: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    helpSection: {
      type: String,
    },
    eventName: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    titleText() {
      if (this.options && this.options.required) {
        return `${this.title} *`;
      } else {
        return this.title;
      }
    },

    computedValue: {
      get: function () {
        if (this.options.isRegExp) {
          return this.modifiedValue.replace(/^\(\?i\)/, "");
        } else {
          return this.modifiedValue;
        }
      },

      set: function (newValue) {
        if (this.options && this.options.lowercase) {
          if (hasValue(newValue)) {
            this.modifiedValue = newValue.toLowerCase();
          }
        } else {
          this.modifiedValue = newValue;
        }
      },
    },
  },

  watch: {
    value(newValue) {
      this.originalValue = newValue;
      this.modifiedValue = newValue;
    },
  },

  created() {
    if (this.value) {
      this.originalValue = this.value;
      this.modifiedValue = this.value;
    }

    if (!this.options) {
      return;
    }

    if (this.options.required) {
      this.rules.push(hasValue);
    }

    if (this.options.isRegExp) {
      this.rules.push(isValidRegExp);
    }

    if (this.options.isInteger) {
      this.rules.push(isInteger);
    }
  },

  methods: {
    helpURL: function () {
      return getUploadConfigHelpURL(this.helpSection);
    },

    clearValue() {
      this.modifiedValue = "";
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    revertValue() {
      this.modifiedValue = this.originalValue;
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    onBlur() {
      let fails = false;

      for (let i = 0; i < this.rules.length; i++) {
        fails = ruleFails(this.rules[i], this.modifiedValue);
        if (fails) {
          break;
        }
      }

      if (fails) {
        return;
      } else {
        this.$eventHub.$emit(this.eventName, this.modifiedValue);
      }
    },
  },
};
</script>

<style></style>
