<template>
  <div class="upload-config-control">
    <v-row>
      <v-checkbox v-bind:label="title" v-model="computedValue"></v-checkbox>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            class="list-button mt-3"
            v-on:click="revertValue"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Undo all changes</span>
      </v-tooltip>

      <v-btn
        v-if="!!helpURL()"
        icon
        link
        class="list-button mt-3"
        :href="helpURL()"
        target="_blank"
      >
        <v-icon size="18" class="mt-0" color="blue darken-1">mdi-help</v-icon>
      </v-btn>
    </v-row>
    <sr-msg-box ref="msgbox"></sr-msg-box>
  </div>
</template>

<script>
import { getUploadConfigHelpURL } from "@/lib/utils.js";

export default {
  components: {},

  data() {
    return {
      modifiedValue: "",
      originalValue: "",
    };
  },

  props: {
    value: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    helpSection: {
      type: String,
    },
    eventName: {
      type: String,
      required: true,
    },
    options: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    computedValue: {
      get: function () {
        return this.modifiedValue;
      },

      set: function (newValue) {
        this.modifiedValue = newValue;
      },
    },
  },

  watch: {
    value(newValue) {
      this.orignalValue = newValue;
      this.modifiedValue = newValue;
    },
  },

  methods: {
    helpURL: function () {
      return getUploadConfigHelpURL(this.helpSection);
    },

    clearValue() {
      this.modifiedValue = false;
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },

    revertValue() {
      this.modifiedValue = this.orignalValue;
      this.$eventHub.$emit(this.eventName, this.modifiedValue);
    },
  },
};
</script>

<style></style>
