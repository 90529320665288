var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"grey","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Global Configuration")]),_c('v-btn',{staticClass:"list-button ml-2",attrs:{"icon":"","link":"","href":_vm.helpURL('global-configuration'),"target":"_blank"}},[_c('v-icon',{staticClass:"mt-0",attrs:{"size":"18","color":"orange darken-4"}},[_vm._v("mdi-help")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('TextEditor',{staticClass:"ml-10 mt-1",attrs:{"value":String(_vm.modifiedConfig['pipeline-id']),"title":"Pipeline ID","eventName":"pipeline-id-updated","options":{
                  required: true,
                  readonly: true,
                }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextEditor',{staticClass:"ml-10 mt-1",attrs:{"value":_vm.modifiedConfig['pipeline-name'],"title":"Pipeline name","eventName":"pipeline-name-updated","options":{
                  required: true,
                }}})],1)],1),_c('v-row',[_c('v-col',[_c('ListEditor',{staticClass:"ml-10 mt-1 mb-0",attrs:{"value":_vm.modifiedConfig['allowed-extensions'],"title":"Allowed extensions","eventName":"allowed-extensions-updated","options":{
                  required: true,
                  lowerCase: true,
                  containsExtensions: true,
                }}})],1)],1),_c('v-row',[_c('v-col',[_c('ListEditor',{staticClass:"ml-10 mt-1",attrs:{"value":_vm.modifiedConfig['ignored-extensions'],"title":"Ignored extensions","eventName":"ignored-extensions-updated","options":{
                  lowerCase: true,
                  containsExtensions: true,
                }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('TextEditor',{staticClass:"ml-10 mt-1",attrs:{"value":_vm.modifiedConfig['skip-until-regexp'],"title":"Skip until regular expression","required":true,"eventName":"skip-until-regexp-updated","options":{
                  isRegExp: true,
                }}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('TextEditor',{staticClass:"ml-10 mt-1",attrs:{"value":_vm.modifiedConfig['skip-lines-regexp'],"title":"Skip lines regular expression","required":true,"eventName":"skip-lines-regexp-updated","options":{
                  isRegExp: true,
                }}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"grey","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Mailbox Configuration")]),_c('v-btn',{staticClass:"list-button ml-2",attrs:{"icon":"","link":"","href":_vm.helpURL('mailbox-configuration'),"target":"_blank"}},[_c('v-icon',{staticClass:"mt-0",attrs:{"size":"18","color":"orange darken-4"}},[_vm._v("mdi-help")])],1)],1),_c('v-card-text',[_c('ListEditor',{staticClass:"ml-10 mt-1",attrs:{"value":_vm.modifiedConfig['allowed-domains'],"title":"Allowed domains","eventName":"allowed-domains-updated","options":{
              required: true,
              domains: true,
              lowerCase: true,
            }}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"grey","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Spreadsheet Configuration")]),_c('v-btn',{staticClass:"list-button ml-2",attrs:{"icon":"","link":"","href":_vm.helpURL('spreadsheet-configuratio'),"target":"_blank"}},[_c('v-icon',{staticClass:"mt-0",attrs:{"size":"18","color":"orange darken-4"}},[_vm._v("mdi-help")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ListEditor',{staticClass:"ml-10 mt-1",attrs:{"value":_vm.modifiedConfig['xls-sheets-to-extract'],"title":"XLS sheets to extract","eventName":"xls-sheets-to-extract-updated","options":{
                  required: false,
                }}})],1),_c('BoolEditor',{staticClass:"ml-10 mt-6",attrs:{"value":_vm.modifiedConfig['xls-use-libreoffice'],"title":"Use Libre Office","eventName":"xls-use-libreoffice-updated"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"grey","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Format Configuration")]),_c('v-btn',{staticClass:"list-button ml-2",attrs:{"icon":"","link":"","href":_vm.helpURL('formats'),"target":"_blank"}},[_c('v-icon',{staticClass:"mt-0",attrs:{"size":"18","color":"orange darken-4"}},[_vm._v("mdi-help")])],1)],1),_c('v-card-text',[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""},model:{value:(_vm.openedPanels),callback:function ($$v) {_vm.openedPanels=$$v},expression:"openedPanels"}},_vm._l((_vm.modifiedConfig.formats),function(item,i){return _c('v-expansion-panel',{key:i},[_c('v-expansion-panel-header',[_vm._v(_vm._s(item.name))]),_c('v-expansion-panel-content',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextEditor',{staticClass:"ml-10 mt-1",attrs:{"value":item.name,"title":"Format name","eventName":"format-name-updated","options":{
                          required: true,
                        }}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('DataTypeEditor',{staticClass:"ml-10 mt-1",attrs:{"value":item['data-type'],"eventName":"format-data-type-updated"}})],1)],1),_c('v-row',[_c('v-col',[_c('ListEditor',{staticClass:"ml-10 mt-1 mb-0",attrs:{"value":item.extensions,"title":"Extensions","eventName":"format_extensions_updated","options":{
                          required: true,
                          lowerCase: true,
                          containsExtensions: true,
                        }}})],1)],1),_c('v-row',[_c('v-col',[_c('FilenameEditor',{attrs:{"value":item.filename,"title":"Filename options","eventName":"format_filename_updated","options":{
                          required: true,
                          lowerCase: true,
                          containsExtensions: true,
                        }}})],1)],1),_c('v-row',[_c('v-col',[_c('ListEditor',{staticClass:"ml-10 mt-1 mb-0",attrs:{"value":item.preprocessors,"title":"Preprocessors","eventName":"format_preprocessors_updated","options":{
                          required: false,
                          lowerCase: false,
                        }}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"code-editor-container"},[_c('prism-editor',{staticStyle:{"border":"5px"},attrs:{"language":"sql"},model:{value:(item['query-string']),callback:function ($$v) {_vm.$set(item, 'query-string', $$v)},expression:"item['query-string']"}})],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('ColumnListEditor',{attrs:{"value":item.columns,"eventName":"columns-updated"}})],1)],1)],1)],1)],1)}),1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"dark":"","color":"grey","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"white--text"},[_vm._v("Format override")]),_c('v-btn',{staticClass:"list-button ml-2",attrs:{"icon":"","link":"","href":_vm.helpURL('format-override'),"target":"_blank"}},[_c('v-icon',{staticClass:"mt-0",attrs:{"size":"18","color":"orange darken-4"}},[_vm._v("mdi-help")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }